<script setup>
import {defineProps, ref} from 'vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

const props = defineProps({
    menuContent: {
        type: Object,
        required: true
    },
    isMobile: Boolean,
})

const isDesktopMenuOpen = ref(true)
function toggleMenu() {
    isDesktopMenuOpen.value = !isDesktopMenuOpen.value;
}
</script>

<template>
    <nav id="nav">
        <ul>
            <li v-if="!props.isMobile">
                <a @click="toggleMenu">
                    <font-awesome-icon :icon="'fa-solid fa-bars'"/>
                    <div>
                        Hide Menu
                    </div>
                </a>
            </li>
            <li v-for="iter in props.menuContent" :key="iter.id">
                <router-link :to="iter.link">
                    <font-awesome-icon :icon="iter.icon"/>
                    <div>
                        {{ iter.name }}
                    </div>
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<style scoped>
#nav ul {
    padding: 0;
    list-style: none;
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 11px;
}

#nav ul li {
    position: relative;
    width: 100%;
    height: 47px;
    cursor: pointer;
    border-radius: 0.75rem;
}

#nav ul li a {
    width: 100%;
    font-size: 16px;
    display: flex;
    grid-gap: 11px;
    justify-content: flex-start;
    align-items: center;
    opacity: 0.8;
    position: relative;
    color: #fff;
    text-decoration: none;
    padding: 11px 22px;
}

#nav .active-link {
    color: #ffffff;
    font-weight: 500;
    opacity: 1;
    border-radius: 0.75rem;
    background: #232e45;
}

#nav ul li svg {
    font-size: 22px;
}

#nav ul li:hover {
    background: #232e4577;
    opacity: 1;
}
</style>