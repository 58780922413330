// CustomTextarea.vue
<template>
  <textarea
          :value=modelValue
          ref="textarea"
          rows="1"
          @focus="resize"
          @keyup="resize"
          @input="$emit('update:modelValue', $event.target.value)"
          :disabled="disabled"
  >
  </textarea>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: String,
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
    },
    emits: ['update:modelValue'],
    mounted() {
        this.resize();
    },
    methods: {
        resize() {
            const {textarea} = this.$refs;
            textarea.style.height = textarea.scrollHeight - 1 + 'px';
        }
    }
}
</script>

<style>
textarea {
    width: 100%;
    min-height: 100%;
    padding: 0.375rem 0.75rem;
    height: 75px;
    font-size: 14px;
    background-color: #20242C !important;
    border-color: #20242C !important;
    color: #ccc !important;
}

</style>