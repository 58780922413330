<script setup>
import {storeToRefs} from 'pinia'

import {Alert, Header, Menu} from '@/components';
import {useAuthStore} from '@/stores';
import {ref} from "vue";

const {user} = storeToRefs(useAuthStore());

const isMobileMenuOpen = ref(false)
function showMobileMenu() {
    isMobileMenuOpen.value = !isMobileMenuOpen.value
}
function closeMobileMenu() {
    isMobileMenuOpen.value = false
}
</script>

<template>
    <Alert/>
    <div v-if="!user" id="login-container">
        <router-view/>
    </div>

    <div v-if="user" class="main-container">
        <Menu :isMobileMenuOpen="isMobileMenuOpen" @closeMobileMenu="closeMobileMenu"/>
        <div class="fill-container">
            <Header @showMobileMenu="showMobileMenu"/>
            <div class="common-block h-100">
                <router-view/>
            </div>
        </div>
    </div>
</template>

<style scoped>
@import "@/assets/main.css";

#login-container {
    margin-top: 70px;
    margin-left: 5px;
    margin-right: 5px;
    max-height: 100% !important;
    max-width: 100% !important;
    padding: 0.5rem !important;
}

.main-container {
    display: flex;
    position: fixed;
    padding: 10px;
    width: 100%;
    height: 100%;
    grid-gap: 10px;
}

.fill-container{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    width: 100%;
    grid-gap: 10px;
}

.common-block {
    border-radius: 30px;
    background-color: rgba(4, 5, 16, 0.4);
    position: relative;
    padding: 22px;
}
</style>