<script setup>
import {storeToRefs} from 'pinia';
import { watch } from 'vue'

import {useAlertStore} from '@/stores';

const alertStore = useAlertStore();
const {alert} = storeToRefs(alertStore);

watch(alert, async() => {
    if (alert.value !== null) {
        const alertType = alert.value.type
        if (alertType === 'alert-success') {
            setTimeout(() => {
                alertStore.clear()
            }, 2000)
        }
    }
})
</script>

<template>
    <div v-if="alert" class="container" id="alert">
        <div class="mx-3 mb-3">
            <div class="alert alert-dismissable" :class="alert.type">
                <button @click="alertStore.clear" class="btn btn-link close">❌</button>
                {{ alert.message }}
            </div>
        </div>
    </div>
</template>

<style scoped>
#alert {
    z-index: 100;
    position: absolute;
    right: 0;
    left: 0;
    top: 10px;
}
</style>