<script setup>
import {defineEmits, defineProps, onMounted, ref} from "vue";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faClock} from '@fortawesome/free-regular-svg-icons'
import {
    faBars,
    faChartLine,
    faCircleInfo,
    faClipboardQuestion,
    faListCheck,
    faUsers,
    faXmark
} from '@fortawesome/free-solid-svg-icons'
import {library} from '@fortawesome/fontawesome-svg-core'

import {Logo, Nav} from './'
import {useAuthStore} from "@/stores";

library.add(faClock)
library.add(faChartLine)
library.add(faListCheck)
library.add(faClipboardQuestion)
library.add(faCircleInfo)
library.add(faXmark)
library.add(faBars)
library.add(faUsers)

const props = defineProps(['isMobileMenuOpen'])
const isMobile = ref(false)

onMounted(() => {
    isMobile.value = window.innerWidth < 651;
})
defineEmits(['closeMobileMenu'])

let enableMembers = false
// check if admin or teamlead or moderator
const {user} = useAuthStore();
let position = null
if (user) {
    position = user.profile.position
}

if (position) {
    if (position === 'teamlead' || position === 'moderator' || position === 'admin') {
        enableMembers = true
    }
}


let menuContent = [
    {
        id: 1,
        name: 'Dashboard',
        link: '/dashboard',
        icon: 'fa-solid fa-chart-line',
    },
    {
        id: 2,
        name: 'Tasks',
        link: '/tasks',
        icon: 'fa-solid fa-list-check',
    },
]
if (enableMembers) {
    menuContent.push(
        {
            id: 3,
            name: 'Members',
            link: '/members',
            icon: 'fa-solid fa-users',
        }
    )
}
menuContent.push(
    {
        id: 4,
        name:' FAQs',
        link: '/faqs',
        icon: 'fa-solid fa-clipboard-question',
    },
    {
        id: 5,
        name: 'About',
        link: '/about',
        icon: 'fa-solid fa-circle-info',
    }
)

</script>

<template>
    <div v-if="props.isMobileMenuOpen || !isMobile" id="menu" class="common-block">
        <button @click="$emit('closeMobileMenu')" class="mobile-only menu-toggle menu-toggle-absolute">
            <font-awesome-icon icon="fa-solid fa-xmark"/>
        </button>
        <Logo/>
        <Nav :menuContent="menuContent" :isMobile="isMobile"/>
    </div>
</template>

<style scoped>
#menu {
    max-width: 264px;
    height: 100%;
    width: 100%;
    top: 0;
    flex-shrink: 0;
}
</style>