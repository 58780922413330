<template>
    <div class="logo">
        <img alt="Logo" class="logo-image" src="@/assets/TD-Logo-bg-remove-cut.png">
    </div>
</template>

<style scoped>
.logo {
    margin: 1px auto 20px;
    filter: contrast(1.8) brightness(1.3);
    width: 70px;
    height: 70px;
    padding: 5px;
    position: relative;
    background-color: transparent;
    background-image: url("@/assets/logo-noise.png");
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: 20s bgPos ease-in-out infinite alternate;
}

@keyframes bgPos {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 512px 512px;
    }
}

.logo-image {
    opacity: 1;
    background-position-y: -10px;
    width: 90%;
    height: 85%;
}
</style>