import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import { router } from '@/router'

import 'bootstrap/dist/css/bootstrap.css'
import 'material-design-icons-iconfont'

const app = createApp(App);

app.use(createPinia());
app.use(router);

app.mount('#app');

import 'bootstrap/dist/js/bootstrap'