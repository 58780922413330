import {createRouter, createWebHistory} from "vue-router";

import {useAlertStore, useAuthStore} from '@/stores';
import accountRoutes from './account.routes';
import baseRoutes from './base.routes';
import tasksRoutes from './tasks.routes';
import membersRoutes from './members.routes';


const routes = [
    {...baseRoutes},
    {...accountRoutes},
    {...tasksRoutes},
    {...membersRoutes},

    {
        path: '/:pathMatch(.*)*',
        name: 'Page Not Found',
        component: () => import('@/views/errors/PathNotFound'),

    },
]

export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: 'active-link',
    routes,
});

router.beforeEach(async (to) => {
    // clear alert on route change
    const alertStore = useAlertStore();
    alertStore.clear();

    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/account/login'];
    const authRequired = !publicPages.includes(to.path);
    const authStore = useAuthStore();

    if (authRequired && !authStore.user) {
        authStore.returnUrl = to.fullPath;
        return '/account/login';
    }

    if (to.path === '/members') {
        let position = null
        if (authStore.user.profile.position) {
            position = authStore.user.profile.position
        }

        if (position) {
            if (position !== 'teamlead' && position !== 'moderator' && position !== 'admin') {
                return '/dashboard'
            }
        }
    }
});