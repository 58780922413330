<script setup>
import {defineEmits} from 'vue'
import {useAuthStore} from '@/stores';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faMoon, faSun} from '@fortawesome/free-regular-svg-icons'
import {faBars} from '@fortawesome/free-solid-svg-icons'
import {library} from '@fortawesome/fontawesome-svg-core'
import {BreadCrumbs} from '@/components'

library.add(faSun)
library.add(faMoon)
library.add(faBars)

const {logout} = useAuthStore();
defineEmits(['showMobileMenu'])

</script>

<template>
    <header class="common-block">
        <div>
            <BreadCrumbs/>
        </div>
        <div class="flex-centered-container desktop-only">
            <div class="mode-switcher">
                <input type="radio" name="mode-switcher" id="modeLight"/>
                <label for="modeLight">
                    <font-awesome-icon icon="fa-regular fa-sun"/>
                </label>
                <input type="radio" name="mode-switcher" id="modeDark" checked/>
                <label for="modeDark">
                    <font-awesome-icon icon="fa-regular fa-moon"/>
                </label>
            </div>
            <div class="dropdown text-end">
                <a href="#" class="d-block link-body-emphasis text-decoration-none dropdown-toggle"
                   data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="https://github.com/mdo.png" alt="mdo" width="32" height="32" class="rounded-circle">
                </a>
                <ul class="dropdown-menu text-small" style="">
                    <li><a class="dropdown-item" href="#">New Task...</a></li>
                    <li><a class="dropdown-item" href="#">Settings</a></li>
                    <li><a class="dropdown-item" href="#">Profile</a></li>
                    <li>
                        <hr class="dropdown-divider">
                    </li>
                    <li>
                        <button @click="logout()" class="dropdown-item">Logout</button>
                    </li>
                </ul>
            </div>
        </div>
        <button @click="$emit('showMobileMenu')" class="mobile-only menu-toggle">
            <font-awesome-icon icon="fa-solid fa-bars"/>
        </button>
    </header>
</template>

<style scoped>
header {
    height: 70px;
    width: 100%;
    top: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mode-switcher{
    padding: 4px;
    background: #1b253b;
    border-radius: 0.375rem;
    width: 91px;
    display: flex;
    grid-gap: 4px;
    position: relative;
}
.mode-switcher input{
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.mode-switcher input:checked + label{
    background: #155e75;
    opacity: 1;
}

.mode-switcher label{
    padding: 0.375rem 0.5rem;
    font-size: 24px;
    color: #fff;
    opacity: 0.5;
    border-radius: 0.375rem;
    width: 40px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.mode-switcher label:hover{
    background: #155e75;
    opacity: 1;
}
</style>