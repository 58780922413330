import {defineStore} from 'pinia';

import {fetchWrapper} from '@/helpers';
import {router} from '@/router';
import {useAlertStore} from '@/stores';

const baseUrl = `${process.env.VUE_APP_API_URL}`;

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        returnUrl: null,
        authorized: null,
    }),
    actions: {
        async login(username, password) {
            try {
                const url_token = `${baseUrl}/api/token/`
                const body_token = { 'username': username, 'password': password }
                const response = await fetchWrapper.post(url_token, body_token);
                const token = response.access
                const token_refresh = response.refresh

                // update pinia state
                this.user = {}
                this.user.token = token;
                this.user.token_refresh = token_refresh;
                const url_profile = `${baseUrl}/api/profiles/get_profile?username=${username}`
                // update pinia state
                this.user.profile = await fetchWrapper.get(url_profile, '');
               

                // store user details and jwt in local storage to keep user logged in between page refreshes
                await localStorage.setItem('user', JSON.stringify(this.user));

                // redirect to previous url or default to home page
                let returnUrl = this.returnUrl
                if (returnUrl === 'account/login' || returnUrl === null) {
                    returnUrl = '/dashboard'
                }

                this.authorized = true
                await router.replace(returnUrl)

            } catch (error) {
                let msg = ''
                if (error === 401){
                    msg = '401 - Unauthorized: Access is denied due to invalid credentials'
                } else {
                    msg = error
                }
                const alertStore = useAlertStore();
                alertStore.error(msg);
                this.logout()
                this.authorized = false
            }
        },
        async getNewToken(refreshToken) {
            try {
                const url_token = `${baseUrl}/api/token/refresh/`
                const body_refresh_token = { 'refresh': refreshToken}
                const response = await fetchWrapper.post(url_token, body_refresh_token);

                const token = response.access

                // update pinia state
                this.user.token = token;
                // store user details and jwt in local storage to keep user logged in between page refreshes
                await localStorage.setItem('user', JSON.stringify(this.user));

                return true

            } catch (error) {
                let msg = ''
                if (error === 401){
                    msg = '401 - Unauthorized: Access is denied due to invalid credentials'
                } else {
                    msg = error
                }
                const alertStore = useAlertStore();
                alertStore.error(msg);
                this.logout()
                return false
            }
        },
        logout() {
            this.authorized = false
            this.returnUrl = null;
            router.replace('/account/login');
            this.user = null;
            localStorage.removeItem('user');
            localStorage.removeItem('tasks');
            localStorage.removeItem('workers');
        }
    }
});